var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reseller"},[_c('v-row',[_c('v-col',[_c('breadcrumb',{attrs:{"items":_vm.breadcrumbs}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary"},on:{"click":function($event){_vm.showAddResellerDialog = true}}},[_c('v-icon',{attrs:{"left":"","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountMultiplePlus)+" ")]),_vm._v(" Add Reseller ")],1)],1)],1),_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-col',{staticClass:"col-md-6"},[_c('search-input',{attrs:{"filters":_vm.searchFilters,"active-filter":_vm.activeSearchFilter},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.resellers,"loading":_vm.isResellersLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"mt-2",attrs:{"indeterminate":"","rounded":""}}),_c('p',{staticClass:"text-center mt-1"},[_vm._v(" Loading...Please wait ")])]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [(_vm.resellers && _vm.resellers.length > 0)?_c('tbody',_vm._l((items),function(reseller){return _c('tr',{key:reseller.id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onResellerClick(reseller.id)}}},[_c('td',[_vm._v(_vm._s(reseller.name))]),_c('td',[_vm._v("Ksh "+_vm._s(_vm._f("formatCurrency")(reseller.account_balance)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(reseller.created_at)))]),_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editReseller(reseller)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",on:{"click":function($event){return _vm.showDeleteResellerDialog(reseller.id, reseller.name)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])}),0):_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isResellersLoading),expression:"!isResellersLoading"}]},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length}},[_vm._v(" No resellers found ")])])])]}}])}),_c('v-divider'),_c('v-pagination',{staticClass:"mt-1",attrs:{"length":_vm.pagination.pageCount,"total-visible":8},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('add-reseller-dialog',{attrs:{"show-dialog":_vm.showAddResellerDialog,"reseller":_vm.reseller},on:{"close":function($event){_vm.showAddResellerDialog = false
      _vm.reseller = {}},"reseller-added":function($event){return _vm.getResellers()}}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"title":'Remove reseller?',"agree-text":'Remove',"message":_vm.deleteResellerMessage},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"agree":function($event){return _vm.deleteReseller()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }