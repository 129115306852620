<template>
  <div class="reseller">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        class="text-right"
        cols="2"
      >
        <v-btn
          color="primary"
          class="mb-5"
          @click="showAddResellerDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiAccountMultiplePlus }}
          </v-icon>
          Add Reseller
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-col class="col-md-6">
          <search-input
            v-model="search"
            :filters="searchFilters"
            :active-filter="activeSearchFilter"
          />
        </v-col>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="resellers"
        :loading="isResellersLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="resellers && resellers.length > 0">
            <tr
              v-for="reseller in items"
              :key="reseller.id"
              style="cursor: pointer"
              @click="onResellerClick(reseller.id)"
            >
              <td>{{ reseller.name }}</td>
              <td>Ksh {{ reseller.account_balance | formatCurrency }}</td>
              <td>{{ reseller.created_at | formatDate }}</td>
              <td @click.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="editReseller(reseller)"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="showDeleteResellerDialog(reseller.id, reseller.name)"
                    >
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isResellersLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No resellers found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <v-pagination
        v-model="pagination.page"
        class="mt-1"
        :length="pagination.pageCount"
        :total-visible="8"
        @input="onPageChange"
      ></v-pagination>
    </v-card>
    <add-reseller-dialog
      :show-dialog="showAddResellerDialog"
      :reseller="reseller"
      @close="
        showAddResellerDialog = false
        reseller = {}
      "
      @reseller-added="getResellers()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :title="'Remove reseller?'"
      :agree-text="'Remove'"
      :message="deleteResellerMessage"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteReseller()"
    />
  </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiAccountMultiplePlus, mdiDownloadCircleOutline,
} from '@mdi/js'
import AddResellerDialog from '@/components/dialogs/AddResellerDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'

export default {
  components: {
    AddResellerDialog,
    ConfirmDialog,
    breadcrumb: BreadCrumb,
    SearchInput,
  },
  data() {
    return {
      sortBy: ['created_at'],
      sortDesc: [true],
      search: '',
      activeSearchFilter: 'users.name',
      searchFilters: [
        {
          text: 'Name',
          value: 'users.name',
        }],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Wallet Balance',
          value: 'wallet_balance',
          sortable: false,
        },
        { text: 'Registered on', value: 'created_at', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiAccountMultiplePlus,
        mdiDownloadCircleOutline,
      },
      reseller: {},
      resellers: [],
      resellersToDownload: [],
      resellersToDownloadFileName: '',
      resellerUrl: 'resellers',
      isResellersLoading: true,
      showAddResellerDialog: false,
      showCreditCustomerAccountDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isResellersRefreshing: false,
      isDownloadCsvButtonLoading: false,
      toDelete: null,
      toDeleteCustomerName: null,
      resellerId: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Resellers',
          disabled: true,
          to: { name: 'resellers' },
        },
      ],
      filters: {
        accountBalanceStatus: [],
      },
      exportTableHeaders: [],
    }
  },
  computed: {
    deleteResellerMessage() {
      return `Are you sure you want to remove ${this.toDeleteCustomerName} from resellers?`
    },
    refreshTable() {
      return `|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getResellers()
    },
  },
  mounted() {
    this.getResellers(this.resellerId)
    if (this.resellerId) {
      this.breadcrumbs.pop()
      this.breadcrumbs.push(
        {
          text: 'Resellers',
          disabled: false,
          to: { name: 'resellers' },
        },
        {
          text: this.resellerId,
          disabled: true,
          to: { name: 'reseller-details' },
        },
      )
    }
  },
  methods: {
    editReseller(reseller) {
      this.reseller = reseller
      this.showAddResellerDialog = true
    },
    showDeleteResellerDialog(id, name) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
      this.toDeleteCustomerName = name
    },
    getResellers: _.debounce(function () {
      this.isResellersLoading = true
      axios
        .get(this.constructCompleteResellersUrl())
        .then(response => {
          this.resellers = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isResellersLoading = false
          this.isResellersRefreshing = false
        })
        .catch(error => {
          this.isResellersLoading = false
          this.isResellersRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    refreshResellers() {
      this.isResellersRefreshing = true
      this.getResellers()
    },
    deleteReseller() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`resellers/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Reseller deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getResellers()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    applyFilters(filters) {
      this.filters = filters
      this.getResellers()
    },
    onResellerClick(resellerId) {
      this.$router.push({ name: 'reseller-details', params: { id: resellerId } })
    },
    onPageChange() {
      this.getResellers()
    },
    constructCompleteResellersUrl() {
      return `${this.resellerUrl}?&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&page=${this.pagination.page}`
    },
  },
}
</script>
